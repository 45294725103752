@media print {
	.MuiCard-root {
		display: block;
		break-inside: avoid;
		page-break-inside: avoid;
		break-before: auto;
		break-after: auto;
		width: 580px;
		margin-left: 0 !important;
	}
	#totals-table {
		break-inside: avoid;
	}
	body {
		background: white;
	}
	#printableArea {
		padding: 0;
		max-width: 100%;
	}
}

#printableArea .MuiCardContent-root {
	padding: 0 !important;
}

#totals-table .MuiTableCell-root,
#list-table .MuiTableCell-root {
	border: 1px solid rgba(224, 224, 224, 1);
	/* padding: 8px; */
}

#list-table .rotate-cells .MuiTableCell-root {
	/* display: block; */
}

#list-table .rotate-cells .rotated-content {
	white-space: nowrap;
	transform: rotate(-90deg);
	transform-origin: center;
	width: 120px;
	display: flex;
	justify-content: center;
	align-items: center;
}
