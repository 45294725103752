body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: rgba(252, 252, 252, 1);
	opacity: 0.8;
	background-image: linear-gradient(
			135deg,
			rgba(248, 248, 248, 1) 25%,
			transparent 25%
		),
		linear-gradient(225deg, rgba(248, 248, 248, 1) 25%, transparent 25%),
		linear-gradient(45deg, rgba(248, 248, 248, 1) 25%, transparent 25%),
		linear-gradient(
			315deg,
			rgba(248, 248, 248, 1) 25%,
			rgba(252, 252, 252, 1) 25%
		);
	background-position: 13px 0, 13px 0, 0 0, 0 0;
	background-size: 26px 26px;
	background-repeat: repeat;
}

html {
	box-sizing: border-box;
	margin: 0;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
